import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mark/di-net2/src/components/layout.js";
import Inquirylist from '../../components/inquirylist';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Residencies`}</h1>
    <p><strong parentName="p">{`DesignInquiry is a collaborative laboratory, a residency space to test ideas and share research. DesignInquiry welcomes experimentation – visual and textual, works-in-process, unfinished essays, lost pages, work-you-never-did-anything-with, or undiscovered gems.`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://designinquiry.substack.com/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Join our newsletter`}</a>{` to be notified when details of new programs are announced.`}</p>
    <p>{`Upcoming programs are here.`}</p>
    <hr></hr>
    <Inquirylist mdxType="Inquirylist" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      